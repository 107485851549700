import en from './locales/en'
import ro from './locales/ro'

export default {
  name: 'common',
  locales: {
    en,
    ro,
  },
}

export { default as TripsList } from './components/TripsList'
