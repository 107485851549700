import { Loadable } from 'ca-admin-core'

import en from './locales/en'
import ro from './locales/ro'

export default {
  name: 'auth',
  routes: [
    {
      path: '/auth/login',
      component: Loadable(() => import('./pages/login')),
      exact: true,
    },
    {
      path: '/auth/register',
      component: Loadable(() => import('./pages/register')),
      exact: true,
    },
  ],
  locales: {
    en,
    ro,
  },
}
