import en from './locales/en'
import ro from './locales/ro'

import reducers from './redux/reducers'
import sagas from './redux/sagas'

export default {
  name: 'accounts',
  locales: {
    en,
    ro,
  },
  reducers,
  sagas,
}

export { default as Company } from './components/TopBar/Company'
export { default as Status } from './components/TopBar/Status'
