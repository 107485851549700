import React from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'
import { isNil } from 'lodash'

@connect(({ wallet }) => ({ wallet }))
export default class Wallet extends React.Component {
  render() {
    const {
      wallet: {
        data: { balance },
      },
    } = this.props

    if (isNil(balance)) {
      return null
    }

    return (
      <>
        <Icon type="wallet" className="text-muted" style={{ fontSize: 16 }} />
        <span className="ml-2">{balance} ron</span>
      </>
    )
  }
}
