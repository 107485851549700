import * as React from 'react'
import { Loadable } from 'ca-admin-core'
import { FormattedMessage } from 'react-intl'

import en from './locales/en'
import ro from './locales/ro'

import reducers from './redux/reducers'
import sagas from './redux/sagas'

export default {
  name: 'dashboard',
  menu: [
    {
      title: <FormattedMessage id="dashboard.menu.title" />,
      key: 'dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-history',
      // roles: ['business'],
    },
  ],
  routes: [
    {
      path: '/dashboard',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
  ],
  locales: {
    en,
    ro,
  },
  reducers,
  sagas,
}
